import React from 'react';
import {    EuiPageHeader,
            EuiImage,  
            EuiFlexGroup
        }   from '@elastic/eui';

import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import hail_logo from '../../../images/hail-logo.png';

const HailCXCenterImageHeader = () => {
    return (
        <EuiPageHeader paddingSize='l' restrictWidth bottomBorder={true}>
            <EuiFlexGroup justifyContent='spaceAround'>
                <EuiImage src={hail_logo} alt='Hail.com' size='1' />
            </EuiFlexGroup>
        </EuiPageHeader>
    )
}

export default HailCXCenterImageHeader;