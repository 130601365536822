import React, { useEffect } from "react"
import queryString from "query-string"
import { navigate } from "gatsby"
import { EuiImage, EuiSpacer, EuiText } from "@elastic/eui"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXCenterImageHeader from "../../components/layout/header/hailcx-center-image-header"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import WelcomeImage from "../../images/welcome.png"
import useIsClient from "../../hooks/isClientHook"
import { useLocation } from "@reach/router"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import { ProcessStates } from '../../entities/constants'
import { saveEntryPointUrl } from "../../entities/utilities"

const WelcomePage = () => {
	const { customerStore, facilityStore } = useStore()
	const { customer, isLoading } = customerStore
	const { isClient, key } = useIsClient()
	const location = useLocation()

  	const { eqn } = queryString.parse(location.search);

	const data = {
		title: "Welcome",
		buttonContent: `Let's get started`,
	}

	const handleClick = () => {
		navigate("/book/confirm-details");
	}

	useEffect(() => {
        //as this page is the entry point of new booking process, save url as entry point url
		saveEntryPointUrl();
    }, [])


	useEffect(() => {

		customerStore.setCustomerEncryptedQuoteNumber(eqn as string);

		customerStore.loadCustomer()
			.then(() => {
				return facilityStore.loadFacilityDetails(customerStore.customer?.registeredAtFacilityId);
			})
			.then(() => {

				//if customer has an active booking, take him to manage booking screen
				const activeBookingId = customerStore.customer?.activeBookingId;

				if (activeBookingId) {
					const activeBookingManageUrl = customerStore.customer?.activeBookingManageUrl;
					navigate(activeBookingManageUrl as string);
					return;
				}

				const customerState = customerStore.customer?.currentStateOfBookingProcessFlow;
				if (customerState) {
					const currentState = ProcessStates.find(state => state.Name === customerState);

					currentState && navigate(`/book/${currentState.RelativeUrl}`);
				}
			})
	}, [customerStore, facilityStore]);

	if (!isClient) return null;

	return (
		<div key={key}>
			<HailCXLayout
				header={<HailCXCenterImageHeader />}
				footer={
					<HailCXSingleButtonFooter
						buttonContent={data.buttonContent}
						isLoading={isLoading}
						handleClick={handleClick}
					/>
				}
			>
				<EuiSpacer />
				<EuiImage src={WelcomeImage} alt={data.title} size="1" />
				<EuiText
					grow={false}
					textAlign="center"
					style={{ marginRight: "auto", marginLeft: "auto" }}
				>
					<EuiSpacer />
					<h1>Welcome {customer?.firstName}!</h1>
					<EuiSpacer />
					<p>Our hail damage assessment is a hassle-free experience.</p>
					<EuiSpacer />
				</EuiText>
			</HailCXLayout>
		</div>
	)
}

export default observer(WelcomePage)
