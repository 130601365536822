import React from 'react';
import { EuiSpacer, EuiBottomBar, EuiButton, EuiFlexGroup, EuiFlexItem, EuiPageContentBody, EuiText, EuiButtonEmpty } from '@elastic/eui';
import '../../../styles/hailcx-buttons.css';
import useIsClient from '../../../hooks/isClientHook';
import HelpModal from '../help-modal';
import { ReactNodeLike } from 'prop-types';

interface Props {
  buttonContent?: string,
  isLoading?: boolean,
  handleClick?: Function,
  legend?: string,
  iconType?: string,
  iconSide?: string,
  color?: string,
  isDisabled?: boolean,
  secondaryLinkRequired?: boolean,
  secondaryLinkContent?: string,
  secondaryLinkHandleClick?: Function | undefined,
  legendComponent?: ReactNodeLike,
}


const HailCXSingleButtonFooter = ({ buttonContent, isLoading, handleClick, legend, legendComponent, iconType, iconSide, color, isDisabled, secondaryLinkRequired, secondaryLinkContent, secondaryLinkHandleClick}: Props) => {
  const { isClient, key } = useIsClient();

  return (
    <div key={key}>
      {isClient ?
        <EuiBottomBar paddingSize="l" >
          <EuiPageContentBody paddingSize={'none'} restrictWidth>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize='l' justifyContent='center'>                
                <EuiFlexItem grow={false} >
                  {legendComponent}
                  {legend ?
                      <>                      
                      <EuiText grow={false} className="eui-textCenter" color='#07C' style={{ fontWeight: 'bold' }} >{legend}</EuiText>
                      <EuiSpacer size='s' />
                      </>
                    :
                    <div></div>
                  }                  
                  {buttonContent && 
                  <EuiButton className="hailcx-primary-button"
                    iconSide={iconSide === 'left' ? 'left' : 'right'}
                    iconType={iconType ?? 'arrowRight'}
                    fill
                    isLoading={isLoading}
                    color={color === 'primary' ? 'primary' : (color === 'danger' ? 'danger' : 'success')}
                    isDisabled={isDisabled}
                    onClick={() => { if(handleClick)handleClick(); }}>
                    {!isLoading ? buttonContent : "Loading..."}
                  </EuiButton>
                  }
                  {secondaryLinkRequired ? (
                  <EuiButtonEmpty
                      className="hailcx-secondary-button"
                      iconSide="left"
                      iconType="arrowLeft"
                      color="text"
                      onClick={() => { if(secondaryLinkHandleClick)secondaryLinkHandleClick(); }}
                    >
                      {secondaryLinkContent}
                    </EuiButtonEmpty>
                  ): (
                    <></>
                  )}
                  <EuiSpacer size='xs' />
                  <HelpModal />                  
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiPageContentBody>
        </EuiBottomBar>
        :
        <div></div>
      }
    </div>
  )
}

export default HailCXSingleButtonFooter;